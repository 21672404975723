



































import {
  Component,
  Prop,
  Vue,
  Provide,
  Watch,
  Mixins,
} from 'vue-property-decorator';

@Component
export default class CoolapseCard extends Vue {

  @Prop({default:false})
  lazy!:boolean

  @Prop({default:true})
  default!:boolean

  @Provide()
  enable = this.default

  @Provide()
  id="N-" + Math.random().toString().replace("0.",'')

}










































import CoolapseCard from "@/components/shared/CoolapseCard.vue";
import { Component, Prop, Vue, Provide } from 'vue-property-decorator';
import { getPaymentHistory } from '@/apis/payment.api';
import {
  mounted,
  errorPopupHandler,
  errorCatchHandler,
  loadings,
} from '@/utils/decorators/VueTimmer';
@Component({
  components: {
    CoolapseCard
  }
})
export default class PaymentHistoryPage extends Vue {
  
  loadings = {
    loadHistory: false
  };

  paging = {
    canNext:false,
    canPrev:false,
  };

  currentShowPaymentDetail = "";

  paymentHistory: any[] = [];

  @mounted
  @loadings("loadings.loadHistory")
  listPaymentHistory() {
    return getPaymentHistory({
      from_hex_id : null,
      limit : 5,
      action : "next"
    }).then(data => {
      console.log(data)
      this.paymentHistory = data.data;
      this.paging.canNext = !data.ended
    });
  }

  @loadings("loadings.loadHistory")
  onPrev(){
    return getPaymentHistory({
      from_hex_id : this.paymentHistory[0] && this.paymentHistory[0].hex_id || "",
      limit: 5,
      action:"previous"
    }).then(data => {
      this.paymentHistory = data.data;
      this.paging.canNext = true
      this.paging.canPrev = !data.ended
    })
  }

  @loadings("loadings.loadHistory")
  onNext(){
    return getPaymentHistory({
      from_hex_id : this.paymentHistory[this.paymentHistory.length - 1] && this.paymentHistory[this.paymentHistory.length - 1].hex_id || "",
      limit: 5,
      action:"next"
    }).then(data => {
      this.paymentHistory = data.data;
      this.paging.canNext = !data.ended
      this.paging.canPrev = true
    })
  }

  showPaymentDetail(payment) {

  }
}
